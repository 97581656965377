var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"fullscreen-banner banner o-hidden p-0",staticStyle:{"background-image":"url(/images/pattern/01.png)"}},[_c('div',{staticClass:"insideText"},[_vm._v("RIZIKISMS")]),_c('div',{staticClass:"align-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-lg-7 col-md-12 order-lg-1 md-mt-5"},[_vm._m(1),_c('p',{staticClass:"lead animated fadeInUp delay-3 duration-4"},[_vm._v(" Our platform revolves around effectively identifying and meeting customer needs through mobile engagement ")]),_c('div',{staticClass:"d-flex align-items-center animated fadeInUp delay-4 duration-5"},[_c('router-link',{staticClass:"btn btn-theme",attrs:{"to":{ name: 'about-us' }}},[_c('span',[_vm._v("Learn More")])])],1)])])])])]),_c('div',{staticClass:"page-content"},[_c('section',{staticClass:"p-0",staticStyle:{"background-image":"url(/images/pattern/02.png)"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-lg-4 col-md-12 md-mt-5"},[_c('div',{staticClass:"featured-item text-center style-2"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"featured-desc"},[_c('p',[_vm._v(" Get real time feedback by sending text menus for data ... ")]),_c('router-link',{staticClass:"btn btn-theme mt-5",attrs:{"to":{ name: 'products.ussd' }}},[_vm._v("Learn More")])],1)])]),_c('div',{staticClass:"col-lg-4 col-md-12 md-mt-5"},[_c('div',{staticClass:"featured-item text-center style-2"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"featured-desc"},[_c('p',[_vm._v(" Handle multiple incoming and outgoing calls from ... ")]),_c('router-link',{staticClass:"btn btn-theme mt-5",attrs:{"to":{ name: 'products.show', params: { id: 'voice' }}}},[_vm._v("Learn More")])],1)])])])])]),_vm._m(7),_vm._m(8),_c('clients')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-5 col-md-12 order-lg-12"},[_c('div',{staticClass:"mouse-parallax"},[_c('div',{staticClass:"bnr-img1 animated fadeInRight delay-4 duration-4"},[_c('img',{staticClass:"img-center rotateme",attrs:{"src":"/default/01.png","alt":""}})]),_c('img',{staticClass:"img-center bnr-img2 animated zoomIn delay-5 duration-4",attrs:{"src":require("../../public/images/banner/code-snap-banner.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"mb-4 animated bounceInLeft delay-2 duration-4"},[_vm._v(" We provide innovative "),_c('span',{staticClass:"font-w-5"},[_vm._v("customer-specific")]),_vm._v(" soft solutions ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-12"},[_c('div',{staticClass:"featured-item text-center style-2"},[_c('div',{staticClass:"featured-icon"},[_c('img',{staticClass:"img-center",attrs:{"src":"/images/feature/01.png","alt":""}})]),_c('div',{staticClass:"featured-title"},[_c('h5',[_vm._v("Bulk SMS")])]),_c('div',{staticClass:"featured-desc"},[_c('p',[_vm._v(" Sharing of timely personalized mass text messaging. ")]),_c('a',{staticClass:"btn btn-theme mt-5",attrs:{"href":"#"}},[_vm._v("Learn More")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featured-icon"},[_c('img',{staticClass:"img-center",attrs:{"src":"/images/feature/02.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featured-title"},[_c('h5',[_vm._v("USSD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featured-icon"},[_c('img',{staticClass:"img-center",attrs:{"src":"/images/feature/03.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featured-title"},[_c('h5',[_vm._v("Voice")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"o-hidden grey-bg mt-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('div',{staticClass:"info-img pos-r"},[_c('img',{staticClass:"img-fluid topBottom",attrs:{"src":require("../assets/theme/images/about/tradesk-about-us.png"),"alt":"image"}})])]),_c('div',{staticClass:"col-lg-6 col-md-12 md-mt-5"},[_c('div',{staticClass:"section-title mb-4"},[_c('h2',{staticClass:"title"},[_vm._v(" About Us ")]),_c('p',{staticClass:"mb-0 text-black"},[_vm._v(" Rizikisms is a customer engagement platform. We provide innovative customer-specific soft solutions around the company. Our platform revolves around effectively identifying and meeting customer needs through mobile engagement. ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-center o-hidden"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-10 ml-auto mr-auto"},[_c('div',{staticClass:"section-title"},[_c('h6',[_vm._v("How It Works")]),_c('h2',{staticClass:"title"},[_vm._v("Three Easy Steps To Get Started.")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-12"},[_c('div',{staticClass:"work-process"},[_c('div',{staticClass:"work-process-inner"},[_c('div',{staticClass:"work-img"},[_c('img',{staticClass:"img-center",attrs:{"src":"/images/how-it-work/01.png","alt":""}}),_c('span',{staticClass:"step-num"},[_vm._v("01")])]),_c('h4',[_vm._v("Create account and login")])])])]),_c('div',{staticClass:"col-lg-4 col-md-12 md-mt-5"},[_c('div',{staticClass:"work-process"},[_c('div',{staticClass:"work-process-inner"},[_c('div',{staticClass:"work-img"},[_c('img',{staticClass:"img-center",attrs:{"src":"/images/how-it-work/02.png","alt":""}}),_c('span',{staticClass:"step-num"},[_vm._v("02")])]),_c('h4',[_vm._v("Create app and pick a service")])])])]),_c('div',{staticClass:"col-lg-4 col-md-12 md-mt-5"},[_c('div',{staticClass:"work-process"},[_c('div',{staticClass:"work-process-inner"},[_c('div',{staticClass:"work-img"},[_c('img',{staticClass:"img-center",attrs:{"src":"/images/how-it-work/03.png","alt":""}}),_c('span',{staticClass:"step-num"},[_vm._v("03")])]),_c('h4',[_vm._v("Top up and enjoy the services")])])])])])])])
}]

export { render, staticRenderFns }