<template>
  <div>
    <section
      class="fullscreen-banner banner o-hidden p-0"
      style="background-image: url(/images/pattern/01.png)"
    >
      <div class="insideText">RIZIKISMS</div>
      <div class="align-center">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 col-md-12 order-lg-12">
              <div class="mouse-parallax">
                <div class="bnr-img1 animated fadeInRight delay-4 duration-4">
                  <img
                    class="img-center rotateme"
                    src="/default/01.png"
                    alt=""
                  />
                </div>
                <img
                  class="img-center bnr-img2 animated zoomIn delay-5 duration-4"
                  src="../../public/images/banner/code-snap-banner.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col-lg-7 col-md-12 order-lg-1 md-mt-5">
              <h1 class="mb-4 animated bounceInLeft delay-2 duration-4">
                We provide innovative
                <span class="font-w-5">customer-specific</span> soft solutions
              </h1>
              <p class="lead animated fadeInUp delay-3 duration-4">
                Our platform revolves around effectively identifying and meeting
                customer needs through mobile engagement
              </p>
              <div
                class="d-flex align-items-center animated fadeInUp delay-4 duration-5"
              >
                <router-link class="btn btn-theme" :to="{ name: 'about-us' }"
                  ><span>Learn More</span></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="page-content">
      <!--feature start-->

      <section
        style="background-image: url(/images/pattern/02.png)"
        class="p-0"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="featured-item text-center style-2">
                <div class="featured-icon">
                  <img class="img-center" src="/images/feature/01.png" alt="" />
                </div>
                <div class="featured-title">
                  <h5>Bulk SMS</h5>
                </div>
                <div class="featured-desc">
                  <p>
                    Sharing of timely personalized mass text messaging. 
                  </p>
                  <a class="btn btn-theme mt-5" href="#">Learn More</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="featured-item text-center style-2">
                <div class="featured-icon">
                  <img class="img-center" src="/images/feature/02.png" alt="" />
                </div>
                <div class="featured-title">
                  <h5>USSD</h5>
                </div>
                <div class="featured-desc">
                  <p>
                    Get real time feedback by sending text menus for data ...
                  </p>
                  <router-link class="btn btn-theme mt-5" :to="{ name: 'products.ussd' }">Learn More</router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="featured-item text-center style-2">
                <div class="featured-icon">
                  <img class="img-center" src="/images/feature/03.png" alt="" />
                </div>
                <div class="featured-title">
                  <h5>Voice</h5>
                </div>
                <div class="featured-desc">
                  <p>
                    Handle multiple incoming and outgoing calls from ... 
                  </p>
                  <router-link class="btn btn-theme mt-5" :to="{ name: 'products.show', params: { id: 'voice' }}">Learn More</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--feature end-->

      <!--content start-->

      <section class="o-hidden grey-bg mt-5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="info-img pos-r">
                <img
                  class="img-fluid topBottom"
                  src="../assets/theme/images/about/tradesk-about-us.png"
                  alt="image"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 md-mt-5">
              <div class="section-title mb-4">
                <h2 class="title">
                  About Us
                </h2>
                <p class="mb-0 text-black">
                  Rizikisms is a customer engagement platform. We provide innovative customer-specific soft solutions around the company. Our platform revolves around effectively identifying and meeting customer needs through mobile engagement. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--content end-->

      <!--step start-->

      <section class="text-center o-hidden">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-10 ml-auto mr-auto">
              <div class="section-title">
                <h6>How It Works</h6>
                <h2 class="title">Three Easy Steps To Get Started.</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="work-process">
                <div class="work-process-inner">
                  <div class="work-img">
                    <img
                      class="img-center"
                      src="/images/how-it-work/01.png"
                      alt=""
                    />
                    <span class="step-num">01</span>
                  </div>
                  <h4>Create account and login</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner">
                  <div class="work-img">
                    <img
                      class="img-center"
                      src="/images/how-it-work/02.png"
                      alt=""
                    />
                    <span class="step-num">02</span>
                  </div>
                  <h4>Create app and pick a service</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner">
                  <div class="work-img">
                    <img
                      class="img-center"
                      src="/images/how-it-work/03.png"
                      alt=""
                    />
                    <span class="step-num">03</span>
                  </div>
                  <h4>Top up and enjoy the services</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--step end-->

      <!--client logo start-->

      <clients />

      <!--client logo end-->
    </div>
  </div>
</template>

<script>
import Clients from '@/components/Clients';

export default {
  components: {
    Clients,
  }
};
</script>
